import { graphql, useStaticQuery } from 'gatsby'
import { SiteMetadata } from '../interfaces/siteMetadata'

export const useSiteMetadata = (): SiteMetadata => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description {
              en
              ja
            }
            author {
              summary {
                en
                ja
              }
            }
            headline {
              en
              ja
            }
            social {
              twitter
            }
          }
        }
      }
    `
  )

  return data.site.siteMetadata
}

import * as React from 'react'
import { Helmet } from 'react-helmet'

import { useSiteMetadata } from '../hooks/useSiteMetadata'

interface Props {
  lang: string
  meta?: [any]
  localTitle?: string
  localDescription?: string
  keywords?: [string]
  socialImage?: string
  socialUrl?: string
  article?: boolean
}

const SEO = ({ lang, meta, localTitle, localDescription, keywords, socialImage, socialUrl, article }: Props) => {
  const siteMetadata = useSiteMetadata()

  const metaDescription = localDescription || siteMetadata.description[lang]
  const siteTitle = siteMetadata.title
  const title = localTitle ? `${localTitle} | ${siteTitle}` : siteTitle
  const additionalMetadata = meta || []

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords ? keywords?.join(', ') : `programming, english, code, life, thought, movie`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: article ? `article` : `website`,
        },
        {
          property: `og:url`,
          content: socialUrl || siteMetadata.siteUrl,
        },
        {
          property: `og:image`,
          content: socialImage || ``,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: socialImage || ``,
        },
      ].concat(additionalMetadata)}
    />
  )
}

export default SEO

import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Center } from './layout'
import twitter from '../../static/twitter.png'
import { SiteMetadata } from '../interfaces/siteMetadata'

interface Props {
  en: boolean
  siteMetadata: SiteMetadata
}

export const Footer = ({ en, siteMetadata }: Props) => {
  return (
    <Center>
      <FooterDiv>
        <div>
          © {new Date().getFullYear()} Eight Bites<span>|</span>
          <Link to={en ? '/en/privacy-policy' : '/privacy-policy'}>Privacy policy</Link>
          <span>|</span>
          <Link to={en ? '/en/contact/' : '/contact'}>Contact</Link>
        </div>
        <a href={`https://twitter.com/${siteMetadata.social.twitter}`}>
          <TwitterIcon alt={'twitter'} width={20} src={twitter} />
        </a>
      </FooterDiv>
    </Center>
  )
}

const FooterDiv = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.footer.height};

  margin-top: ${({ theme }) => theme.footer.topMargin};
  border-top: ${({ theme }) => theme.border};

  color: ${({ theme }) => theme.grey} !important;
  font-size: ${({ theme }) => theme.font.s};

  span {
    margin: 0 10px;
  }

  a {
    color: ${({ theme }) => theme.grey} !important;
    outline: none;
  }
`

const TwitterIcon = styled.img`
  opacity: 0.8;
  width: 20px;
`

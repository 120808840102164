import * as React from 'react'
import { navigate } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'

import { Header } from './header'
import { Footer } from './footer'
import { theme } from '../styles/theme'
import { SiteMetadata } from '../interfaces/siteMetadata'
import { Location } from '../interfaces/gatsby'

interface Props {
  en: boolean
  location: Location
  children: any
  siteMetadata: SiteMetadata
  article?: boolean
}

export const Layout = ({ en, location, children, siteMetadata, article = false }: Props) => {
  const switchLanguage = () => {
    if (location.pathname.split('/')[1] === 'en') navigate(location.pathname.slice(3) || '/')
    else navigate(`/en${location.pathname}`)
  }

  return (
    <ThemeProvider theme={theme}>
      <Header en={en} switchLanguage={switchLanguage} siteMetadata={siteMetadata} article={article} />
      <Main>{children}</Main>
      <Footer en={en} siteMetadata={siteMetadata} />
    </ThemeProvider>
  )
}

export const Center = styled.div`
  max-width: ${({ theme }) => theme.content.maxWidth};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.content.minSideMargin};

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.small}) {
    padding: 0 15px;
  }
`

const Main = styled.main`
  min-height: calc(
    100vh - ${({ theme }) => theme.header.height} - ${({ theme }) => theme.footer.height} -
      ${({ theme }) => theme.footer.topMargin}
  );
`

import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Center } from './layout'
import { SiteMetadata } from '../interfaces/siteMetadata'

interface Props {
  en: boolean
  switchLanguage: () => void
  siteMetadata: SiteMetadata
  article: boolean
}

export const Header = ({ en, switchLanguage, siteMetadata, article }: Props) => {
  return (
    <Center>
      <HeaderDiv>
        {article ? (
          <Title>
            <Link to={en ? '/en' : '/'}>{siteMetadata.title}</Link>
          </Title>
        ) : (
          <H1>
            <Link to={en ? '/en' : '/'}>{siteMetadata.title}</Link>
          </H1>
        )}

        <Menu>
          <Link to={en ? '/en/categories' : '/categories'}>
            categories
            <ShortBorder />
          </Link>
          <ToggleButton role={'button'} tabIndex={0} onKeyPress={switchLanguage} onClick={switchLanguage}>
            {en ? (
              <>
                EN - <span>JA</span>
              </>
            ) : (
              <>
                <span>EN</span> - JA
              </>
            )}
          </ToggleButton>
        </Menu>
      </HeaderDiv>
    </Center>
  )
}

const HeaderDiv = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.header.height};
`

const H1 = styled.h1`
  height: min-content;
  font-size: ${({ theme }) => theme.font.h1};
  margin-bottom: 0;
`

const Title = styled.div`
  height: min-content;
  font-size: ${({ theme }) => theme.font.h1};
  font-weight: 500;
`

const Menu = styled.div`
  display: flex;

  > a {
    font-weight: 500;
  }
  > a:hover * {
    border-color: ${({ theme }) => theme.accentMagenta};
    width: 0;
  }
`

const ToggleButton = styled.div`
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1.8rem;
  margin-left: max(20px, 8vw);

  span {
    color: ${({ theme }) => theme.lightGrey};
    cursor: pointer;
  }
`

const ShortBorder = styled.div`
  border-bottom: 1.5px #333 solid;
  width: 80%;
  margin: 0 auto;
  transition: width 0.4s;
`
